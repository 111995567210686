
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomerForm",
  props: {
    name: String,
    phone: String,
    email: String,
    address: String,
    error: Boolean,
    errorEmail: Boolean,
    phoneError: Boolean,
  },
  emits: ["update:name", "update:phone", "update:email", "update:address"],
});

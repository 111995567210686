import { reactive, ref } from "vue";

import { CUSTOMER_FIELDS } from "@/graphql/customer/customer.sdl";
import {
  Customer,
  CustomerPagination,
  PaginateCustomersInput,
  QueryPaginateCustomersArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useQuery, useResult } from "@vue/apollo-composable";
import { InitialPagination, PAGINATION_META } from "@/graphql/utils/pagination";
import { activeActivity } from "@/plugins/i18n";
import { filterQuery } from "@/graphql/utils/utils";
import { FilterMatchMode, FilterOperator } from "primevue/api";

type PaginateCustomersData = {
  paginateCustomers: CustomerPagination;
};

const PAGINATE_CUSTOMERS = gql`
  query PaginateCustomers($input: PaginateCustomersInput!){
    paginateCustomers(input: $input){
      items{
        ${CUSTOMER_FIELDS}
      }
      ${PAGINATION_META}
    }
  }
`;
const CUSTOMER_FILTER = {
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  phone: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  email: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  address: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
};
export const usePaginateCustomers = (limit = 20) => {
  const input = reactive<PaginateCustomersInput>({
    activityId: activeActivity.value.id,
    limit,
    page: 1,
    keyword: `c.name LIKE "%%"`,
  });
  const filters = ref({ ...CUSTOMER_FILTER });
  const { loading, result } = useQuery<
    PaginateCustomersData,
    QueryPaginateCustomersArgs
  >(PAGINATE_CUSTOMERS, { input });

  function submitSearch(field = "global", filterCallback = null) {
    if (field !== "global") {
      if (filterCallback != null) filterCallback();
      const { value, matchMode } = filters.value[field].constraints[0];
      input.keyword = filterQuery(matchMode, `c.${field}`, value);
    } else {
      filters.value = { ...CUSTOMER_FILTER };
      const value = filters.value.global.value;
      const bName = `c.name LIKE "%${value}%"`;
      const bPhone = `c.phone LIKE "%${value}%"`;
      const bEmail = `c.email LIKE "%${value}%"`;
      const bAdress = `c.address LIKE "%${value}%"`;
      input.keyword = `(${bName} OR ${bPhone} OR ${bEmail} OR ${bAdress})`;
    }
  }
  function clearFilter() {
    filters.value = { ...CUSTOMER_FILTER };
    input.keyword = `c.name LIKE "%%"`;
  }
  const customers = useResult<
    PaginateCustomersData,
    CustomerPagination,
    CustomerPagination
  >(result, InitialPagination, (res) => {
    return {
      ...res.paginateCustomers,
      items: res.paginateCustomers.items.map((cu) => ({
        ...cu,
        createdAt: new Date(cu.createdAt),
      })),
    };
  });

  const onFilter = (customers: Customer[]) => {
    if (!customers.length) {
      submitSearch("global");
    }
  };

  return {
    loading,
    customers,
    input,
    submitSearch,
    filters,
    clearFilter,
    onFilter,
  };
};

import {
  MutationDeleteCustomerArgs,
  CustomersDeleteOutput,
} from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";
import { deletePaginationCache } from "@/graphql/utils/pagination";
import { useConfirm } from "primevue/useconfirm";
import { activeActivity } from "@/plugins/i18n";

type DeleteCustomerData = {
  deleteCustomer: CustomersDeleteOutput;
};

const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
      ticketsCount
      id
    }
  }
`;

export const useDeleteCustomer = () => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();

  const {
    loading: loadingDelete,
    mutate,
    onDone,
    onError,
  } = useMutation<DeleteCustomerData, MutationDeleteCustomerArgs>(
    DELETE_CUSTOMER,
    {
      update(cache, { data }) {
        if (data?.deleteCustomer) {
          cache.modify({
            fields: {
              paginateCustomers(existing: any, { toReference, readField }) {
                return deletePaginationCache(
                  [data.deleteCustomer.id],
                  existing,
                  readField,
                  toReference
                );
              },
            },
          });
        }
      },
    }
  );

  onDone(({ data, errors }) => {
    if (data?.deleteCustomer) {
      const { id, ticketsCount } = data.deleteCustomer;
      toast.add({
        severity: id && !ticketsCount ? "success" : "warn",
        summary: t("customer.delete.title"),
        detail: t(
          `customer.delete.${ticketsCount ? "used" : id ? "success" : "failed"}`
        ),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else if (errors) {
      toast.add({
        severity: "warn",
        summary: t("customer.delete.title"),
        detail: t(`customer.delete.failed`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  onError(() => {
    toast.add({
      severity: "warn",
      summary: t("customer.error.delete"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  function submitDelete(event: any, customerId: string) {
    confirm.require({
      target: event.currentTarget,
      message: t("delete.confirm"),
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("no"),
      acceptLabel: t("yes"),
      acceptClass: "p-button-warning",
      accept: () => {
        void mutate({
          input: {
            customerId,
            activityId: activeActivity.value.id,
          },
        });
      },
    });
  }

  return { submitDelete, loadingDelete };
};

import { Customer, MutationCustomerCreateArgs } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { CUSTOMER_FIELDS } from "@/graphql/customer/customer.sdl";
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";
import { addPaginationCache } from "@/graphql/utils/pagination";
import { useValidation } from "vue3-form-validation";
import { trim } from "@/graphql/utils/utils";

type CreateCustomerData = {
  customerCreate: Customer;
};

const MUTATION = gql`
    mutation CustomerCreate($input: CustomerCreateInput!) {
        customerCreate(input: $input){
          ${CUSTOMER_FIELDS}
        }
    }
`;

export const useCreateCustomer = () => {
  const dialog = ref<boolean>(false);
  const toast = useToast();
  const { t } = useI18n();
  const { form, hasError, validateFields, resetFields } = useValidation({
    name: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    phone: {
      $value: "",
    },
    email: {
      $value: "",
    },
    address: {
      $value: "",
    },
  });

  const { loading, mutate, onDone } = useMutation<
    CreateCustomerData,
    MutationCustomerCreateArgs
  >(MUTATION, {
    update: (cache, { data }) => {
      if (data?.customerCreate) {
        cache.modify({
          fields: {
            paginateCustomers: (existing: any, { toReference }) => {
              return addPaginationCache(
                data.customerCreate,
                existing,
                toReference
              );
            },
          },
        });
      }
    },
  });

  onDone(({ data, errors }) => {
    if (errors?.length) {
      let detail = t("update.failed");
      if (errors[0].message.includes("email"))
        detail = t("customer.exists", { contact: form.email.$value });
      else if (errors[0].message.includes("phone"))
        detail = t("customer.exists", { contact: form.phone.$value });
      toast.add({
        severity: "error",
        summary: t("update.title"),
        detail,
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else if (data?.customerCreate) {
      toast.add({
        severity: "success",
        summary: t("customer.create.title"),
        detail: t(`customer.create.success`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      dialog.value = false;
      resetFields();
    } else {
      toast.add({
        severity: "warn",
        summary: t("customer.create.title"),
        detail: t(`customer.create.failed`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  function createCustomer() {
    validateFields()
      .then((input) => {
        trim(input);
        void mutate({ input });
      })
      .catch(() => ({}));
  }

  return {
    loading,
    dialog,
    form,
    hasError,
    createCustomer,
    resetFields,
  };
};
